
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoRegular.ttf"); font-family : "Nunito"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterBold.ttf"); font-family : "Inter"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoBold.ttf"); font-family : "Nunito"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterSemiBold.ttf"); font-family : "Inter"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoMedium.ttf"); font-family : "Roboto"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterMedium.ttf"); font-family : "Inter"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterExtraBold.ttf"); font-family : "Inter"; font-weight : 800;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoMedium.ttf"); font-family : "Nunito"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoBlack.ttf"); font-family : "Nunito"; font-weight : 900;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/DMSansRegular.ttf"); font-family : "DM Sans"; font-weight : 400;  }
:root {
  --gray90: #212529;
  --primary-light: #f1f2fe;
  --primary-heavy: #1a3bff;
  --primary: #727cf5;
  --green: #0acf97;
  --green-hover: #00b985;
  --red: #fa5c7c;
  --light-slate-gray: #86999c;
}

.colum {
  min-height: 100px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  border: 1px rgba(0, 0, 0, .4);
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 10px;
  font-size: 18px;
  display: flex;
}

.heading-6 {
  opacity: .3;
  color: #313a46;
  font-size: 15px;
  font-weight: 700;
  line-height: 16.47px;
}

.body-parrafo-2 {
  opacity: .3;
  color: #313a46;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.6px;
}

.pin {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fa5c7c;
  border-radius: 4px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  padding: 2px 8px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.dashboard-pin {
  color: #f1f2fe;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 11.25px;
}

.frame-15 {
  width: 100%;
  height: 100%;
  max-width: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.pin-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fa5c7c;
  border-radius: 4px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  display: flex;
  position: absolute;
  top: 0;
  left: 158px;
}

.frame-1079 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.bold-h3-heading-2 {
  color: var(--gray90);
  font-size: 28px;
  font-weight: 700;
}

.body-base-text-2 {
  color: var(--gray90);
  font-size: 16px;
  font-weight: 400;
}

.vectors-wrapper-2 {
  height: 160px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wrapper-video {
  width: 100%;
  outline-offset: 0px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px #000;
  border-radius: 8px;
  outline: 1px solid #ced4da;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.wrapper-video:hover {
  border: 1px none var(--primary-heavy);
  outline-offset: 0px;
  outline: 1px solid #1a3bff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.vectors-wrapper-3 {
  height: 85.6032px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-4 {
  width: 108.2px;
  height: 105px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bold-h3-heading-3 {
  color: #1a3bff;
  font-size: 28px;
  font-weight: 700;
}

.div-block {
  width: 100%;
}

.body-2 {
  background-color: #fff;
}

.body-3 {
  background-image: url('assets/images/Routemapfondo.png');
  background-position: 50%;
  background-size: cover;
}

.section-4 {
  justify-content: center;
  align-items: center;
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.div-block-2 {
  width: 500px;
  height: 600px;
  background-color: #fff;
  border-radius: 8px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.container-2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.wrapper-2 {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: left;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 24px;
  display: flex;
}

.bold-h3-heading-4 {
  color: #727cf5;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

.title {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.bold-h5-heading {
  color: #212529;
  text-align: left;
  margin-bottom: 8px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.wrapper-3 {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.inputnombre {
  width: 100%;
  max-width: 364px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.input {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 16px;
  display: flex;
}

.body-base-text-3 {
  color: #adb5bd;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.wrapper-4 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 24px;
  display: flex;
}

.title-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.wrapper-5 {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.input-asignatura {
  width: 100%;
  max-width: 364px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.button-2 {
  background-color: var(--primary);
  border-radius: 8px;
  flex-direction: row;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.form-block {
  width: 100%;
}

.text-field, .text-field-2 {
  color: #adb5bd;
  border-radius: 4px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
}

.div-block-3 {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.div-block-4 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.div-block-5 {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.logo-header {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  display: flex;
}

.vectors-wrapper-5 {
  height: 36.1836px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-link {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button-3 {
  background-color: var(--primary-heavy);
  color: var(--primary-light);
  text-align: left;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  transition-property: none;
}

.hero-heading-left {
  width: 100%;
  min-height: 500px;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-image: url('assets/images/principal-transparente.png'), linear-gradient(3deg, #3B45BF, #727cf5);
  background-position: 100%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 900px, auto;
  justify-content: center;
  align-items: center;
  padding: 64px 24px;
  display: flex;
}

.container-3 {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px 10px;
  display: flex;
}

.column {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.lead-text---regular {
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
}

.column-2 {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.image-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image {
  width: 130px;
  height: 130px;
  background-color: var(--green);
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image:hover {
  background-color: var(--green-hover);
}

.vectors-wrapper-6 {
  width: 140px;
  height: 140px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.title-copy-goes-here-be-awesome-0 {
  color: #000;
  letter-spacing: undefinedundefined;
  font-family: Inter, sans-serif;
  font-size: 52px;
  font-weight: 700;
}

.title-copy-goes-here-be-awesome-1 {
  color: #000;
  letter-spacing: undefinedundefined;
  font-family: Inter, sans-serif;
  font-size: 64px;
  font-style: italic;
  font-weight: 400;
}

.wrapper-hero {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.heading {
  color: var(--primary-light);
  font-family: Inter, sans-serif;
}

.heading-0 {
  color: var(--primary-light);
  letter-spacing: undefinedundefined;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  display: inline;
}

.heading-1 {
  color: var(--primary-light);
  letter-spacing: undefinedundefined;
  font-family: Inter, sans-serif;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.navbar {
  background-color: #fff;
}

.container-4 {
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.conteiner-nav {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: flex;
}

.section {
  background-color: var(--primary-light);
  padding-top: 40px;
  padding-bottom: 40px;
}

.wrapper-6 {
  width: 100%;
  max-width: none;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.wrapper-titulo {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.lead-text---regular-2 {
  color: #54595e;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
}

.wrapper-stats {
  width: 100%;
  height: 252px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.vectors-wrapper-7 {
  height: 100px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.extrabold-h1-heading-2 {
  color: #212529;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
}

.extrabold-h1-heading-3 {
  color: #727cf5;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
}

.container-3-item {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: flex;
}

.frame-2 {
  height: 216.196px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-1 {
  border-radius: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}

.vectors-wrapper-8 {
  width: 80px;
  height: 79.9844px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.semi-bold-h3-heading {
  color: #727cf5;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.paragraph {
  color: #54595e;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}

.paragraph-0 {
  color: #54595e;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.paragraph-1 {
  color: #54595e;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

.wrapper-info {
  outline-color: var(--primary-heavy);
  outline-offset: 0px;
  background-color: #fff;
  border-radius: 8px;
  outline-width: 1px;
  outline-style: solid;
  padding: 10px 28px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.frame-1080 {
  height: 213.387px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-1081 {
  border-radius: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}

.vectors-wrapper-9 {
  width: 56.725px;
  height: 77.1782px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paragraph-2 {
  color: #54595e;
  font-size: 20px;
  font-weight: 400;
}

.paragraph-3 {
  color: #54595e;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

.frame-1082 {
  height: 216.212px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-1083 {
  border-radius: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 16px 16px 0;
  display: flex;
}

.vectors-wrapper-10 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paragraph-4 {
  color: #54595e;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}

.paragraph-5 {
  color: #54595e;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 900;
}

.frame-21 {
  grid-column-gap: 78px;
  grid-row-gap: 78px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  display: flex;
}

.frame-9 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.lead-text---regular-3 {
  color: #54595e;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
}

.frame-20 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.vector {
  object-fit: cover;
}

.frame-10 {
  width: 100%;
  max-width: 528px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-5 {
  width: 100%;
  max-width: 528px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.frame-4 {
  width: 100%;
  height: 94px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border: 1px solid var(--primary-heavy);
  border-radius: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 20px;
  display: flex;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.vectors-wrapper-11 {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border: 1px #000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.body-strong {
  color: #54595e;
  font-family: Nunito, sans-serif;
  font-size: 17px;
  font-weight: 700;
}

.button-4 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.button-5 {
  height: 50px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--primary-heavy);
  border-radius: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  box-shadow: 0 4px 10px rgba(114, 124, 245, .5);
}

.button-5.body-strong-2 {
  background-color: var(--primary);
}

.button-5.body-strong-2:hover {
  background-color: var(--primary-heavy);
}

.body-strong-2 {
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
}

.extrabold-h1-heading-5 {
  color: #212529;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
}

.extrabold-h1-heading-6 {
  color: #727cf5;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
}

.extrabold-h1-heading-7 {
  color: #1a3bff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
}

.section-5 {
  padding-top: 40px;
  padding-bottom: 40px;
  display: block;
}

.section-6 {
  min-height: 600px;
  background-image: url('assets/images/Routemapfondo.png');
  background-position: 50%;
  background-size: cover;
  padding-top: 0;
  padding-bottom: 20px;
  display: flex;
}

.frame-1084 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-bottom: 20px;
  display: flex;
}

.extrabold-h1-heading-8 {
  color: #f1f2fe;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
}

.lead-text---regular-4 {
  color: #f1f2fe;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
}

.fechas {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.tooltip-roadmap {
  width: 250px;
  height: 100px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: var(--green);
  text-align: center;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: static;
}

.tooltip-roadmap.enero {
  background-color: var(--primary-heavy);
}

.tooltip-roadmap.marzo {
  background-color: var(--red);
}

.vectors-wrapper-12 {
  height: 121px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.diciembre-2023-gu-as-de-aprendizaje-activo {
  position: static;
  top: 0;
  bottom: auto;
  left: auto;
}

.tooltip-roadmap-2 {
  width: 100%;
  max-width: 259px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.vectors-wrapper-13 {
  height: 121px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.enero-2024-actividades {
  position: absolute;
  top: 32px;
  left: 22.0002px;
}

.diciembre-2023-gu-as-de-aprendizaje-activo-0 {
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 800;
  position: static;
  top: 32px;
  left: 22px;
}

.diciembre-2023-gu-as-de-aprendizaje-activo-1 {
  color: #fff;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: static;
  top: 0;
  left: 0;
}

.enero-2024-actividades-0 {
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 800;
  position: absolute;
  top: 32px;
  left: 22.0002px;
}

.enero-2024-actividades-1 {
  color: #fff;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 32px;
  left: 22.0002px;
}

.span {
  color: var(--primary-light);
}

.container-3-copy {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: flex;
}

.button-6 {
  background-color: var(--green);
  justify-content: center;
  align-items: center;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.button-6:hover {
  background-color: var(--green-hover);
}

.section-7 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.div-block-6 {
  max-width: 1200px;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.frame-1085 {
  width: 50%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 10px;
  display: flex;
}

.extrabold-h1-heading-9 {
  color: #1a3bff;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 800;
}

.paragraph-6 {
  color: #9795b5;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
}

.paragraph-7 {
  color: #9795b5;
  font-family: Nunito, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
}

.paragraph-8 {
  color: #9795b5;
  font-family: Nunito, sans-serif;
  font-size: 21px;
  font-weight: 800;
  line-height: 36px;
}

.div-block-7 {
  width: 50%;
  background-color: var(--primary-light);
  border-radius: 8px;
  padding: 40px 20px;
}

.field-label {
  color: var(--primary);
  font-family: Inter, sans-serif;
}

.text-field-3, .text-field-4 {
  border-radius: 4px;
}

.form-2 {
  display: block;
}

.div-block-8 {
  width: 100%;
  display: block;
}

.div-block-9 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  display: flex;
}

.wrapper-modal {
  z-index: 99;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.wrapper-fondo {
  z-index: 30;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .49);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.frame-1088 {
  z-index: 60;
  background-color: #fff;
  border-radius: 8px;
  flex-wrap: wrap;
  flex: 0 auto;
  justify-content: flex-start;
  align-self: center;
  align-items: stretch;
  display: flex;
  position: relative;
}

.vectors-wrapper-14 {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.frame-1087 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: rgba(255, 255, 255, 0);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  flex-direction: column;
  flex: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  padding: 77px 40px;
  display: flex;
  position: relative;
}

.wrapper-7 {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  border-radius: 8px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px;
  display: flex;
}

.frame-1089 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.bold-h1-heading {
  color: #212529;
  letter-spacing: -.02em;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.body-base-text-4 {
  color: #212529;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.inputoa {
  width: 100%;
  max-width: 364px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.input-2 {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 16px;
  display: flex;
}

.body-base-text-5 {
  color: #adb5bd;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.vectors-wrapper-15 {
  width: 16px;
  height: 16px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 27%;
  bottom: 0%;
  left: auto;
  right: -7%;
}

.frame-1090 {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  display: flex;
}

._2-2-11-checkbox {
  height: 34px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.vectors-wrapper-16 {
  width: 18px;
  height: 18px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.body-small {
  color: rgba(0, 0, 0, .87);
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.body-small-2 {
  color: #abb5be;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.button-7 {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #727cf5;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.button-8 {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
}

.form-block-2 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  display: flex;
}

.text-field-5 {
  width: 100%;
  border-radius: 8px;
  font-family: Nunito, sans-serif;
}

.text-field-6 {
  width: 100%;
  border-radius: 8px;
  font-family: Nunito, sans-serif;
  position: relative;
}

.div-block-10 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  display: flex;
  position: relative;
}

.submit-button {
  width: 100%;
  display: inline-block;
}

.frame-1091 {
  cursor: pointer;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 2%;
  bottom: auto;
  left: auto;
  right: 2%;
}

.vectors-wrapper-17 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbar-2 {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.field-label-2, .field-label-3 {
  font-family: Inter, sans-serif;
}

.frame-24 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  display: flex;
}

.vectors-wrapper-18 {
  width: 62px;
  height: 62px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wrapper-back {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.div-block-11 {
  width: 800px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 90%;
  }

  .container.adaptive {
    width: 728px;
  }

  .nav {
    width: 75%;
  }

  .bold-h3-heading {
    line-height: 28px;
  }

  .heading-5 {
    text-align: left;
  }

  .boton-generador {
    width: 95%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .heading-6, .body-parrafo-2 {
    text-align: left;
  }

  .pin {
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .wrapper-video {
    display: none;
  }

  .button-3 {
    display: inline-block;
  }

  .hero-heading-left {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-image: linear-gradient(to right, #3B45BF, rgba(114, 124, 245, 0)), url('assets/images/principal-transparente.png'), linear-gradient(113.52deg, #3B45BF, #727cf5);
    background-position: 0 0, 100%, 0 0;
    background-repeat: repeat, no-repeat, repeat;
    background-size: auto, cover, auto;
    padding-left: 0;
    padding-right: 0;
  }

  .container-3 {
    width: 95%;
    max-width: none;
    flex-direction: column;
    align-items: center;
  }

  .column {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .lead-text---regular {
    line-height: 25px;
  }

  .image {
    justify-content: center;
  }

  .wrapper-hero {
    width: 400px;
    align-items: flex-start;
  }

  .heading-0 {
    line-height: 40px;
  }

  .heading-1 {
    font-size: 50px;
    line-height: 50px;
  }

  .container-4 {
    max-width: 0;
    min-width: 90%;
  }

  .nav-menu {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .menu-button {
    display: none;
  }

  .brand {
    padding-left: 0;
  }

  .conteiner-nav {
    width: 95%;
    max-width: none;
    flex-direction: row;
    align-items: center;
  }

  .wrapper-6 {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .wrapper-stats {
    height: auto;
  }

  .extrabold-h1-heading-2 {
    color: var(--gray90);
  }

  .extrabold-h1-heading-3 {
    color: var(--primary);
  }

  .container-3-item {
    width: 95%;
    max-width: none;
    flex-direction: row;
    align-items: center;
  }

  .frame-2 {
    height: auto;
  }

  .semi-bold-h3-heading {
    line-height: 34px;
  }

  .paragraph-0 {
    line-height: 25px;
  }

  .wrapper-info {
    height: 340px;
    object-fit: fill;
  }

  .frame-1083 {
    justify-content: flex-start;
    align-items: flex-end;
  }

  .vectors-wrapper-10 {
    align-items: flex-end;
  }

  .frame-21 {
    width: 95%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .frame-9 {
    width: 70%;
  }

  .extrabold-h1-heading-4 {
    text-align: center;
    line-height: 30px;
  }

  .lead-text---regular-3 {
    line-height: 25px;
  }

  .frame-20 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
  }

  .vector {
    width: 500px;
    height: auto;
    max-width: none;
    object-fit: contain;
    align-self: center;
  }

  .frame-10 {
    align-items: center;
  }

  .frame-4 {
    border-color: var(--primary-heavy);
  }

  .extrabold-h1-heading-5, .extrabold-h1-heading-6, .extrabold-h1-heading-7 {
    line-height: 40px;
  }

  .section-5 {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .fechas {
    flex-direction: column;
  }

  .container-3-copy {
    width: 95%;
    max-width: none;
    flex-direction: row;
    align-items: center;
  }

  .section-7 {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .div-block-6 {
    flex-direction: column;
  }

  .frame-1085 {
    width: 100%;
  }

  .extrabold-h1-heading-9 {
    line-height: 48px;
  }

  .div-block-7 {
    width: 100%;
  }

  .wrapper-modal {
    display: none;
  }

  .frame-1088 {
    flex-flow: row;
  }

  .vectors-wrapper-14 {
    display: none;
  }

  .wrapper-7, .frame-1089, .bold-h1-heading {
    text-align: left;
  }

  .div-block-11 {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .colum {
    margin-bottom: 20px;
  }

  .grid {
    min-width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .container.adaptive {
    width: 568px;
  }

  .who-donit {
    width: 50%;
    float: none;
    text-align: center;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .nav {
    width: 100%;
    float: none;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .boton-generador {
    width: 100%;
  }

  .wrapper-video {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .vectors-wrapper-3 {
    width: 150px;
    height: auto;
    object-fit: cover;
  }

  .vectors-wrapper-4 {
    width: 80px;
    height: auto;
  }

  .bold-h3-heading-3 {
    font-size: 20px;
    line-height: 28px;
  }

  .logo-header {
    width: 250px;
  }

  .vectors-wrapper-5 {
    object-fit: contain;
  }

  .hero-heading-left {
    background-image: linear-gradient(to bottom, #3B45BF, rgba(114, 124, 245, 0)), url('assets/images/principal-transparente.png'), linear-gradient(113.52deg, #3B45BF, #727cf5);
    background-position: 0 0, 100%, 0 0;
    background-repeat: repeat, no-repeat, repeat;
    background-size: auto, cover, auto;
  }

  .container-3 {
    width: 95%;
    flex-direction: column;
  }

  .column {
    text-align: center;
  }

  .lead-text---regular {
    line-height: 25px;
  }

  .vectors-wrapper-6 {
    width: 100px;
    object-fit: contain;
  }

  .wrapper-hero {
    width: 100%;
    align-items: center;
  }

  .heading-1 {
    font-size: 45px;
    line-height: 50px;
  }

  .brand {
    padding-left: 0;
  }

  .conteiner-nav {
    width: 95%;
  }

  .lead-text---regular-2 {
    line-height: 25px;
  }

  .wrapper-stats {
    height: auto;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .extrabold-h1-heading-2, .extrabold-h1-heading-3 {
    font-size: 32px;
  }

  .container-3-item {
    width: 95%;
  }

  .frame-2 {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  .frame-1 {
    flex-direction: row;
  }

  .wrapper-info {
    height: auto;
    padding-bottom: 20px;
  }

  .frame-9 {
    width: 90%;
  }

  .extrabold-h1-heading-4, .extrabold-h1-heading-5, .extrabold-h1-heading-6, .extrabold-h1-heading-7 {
    font-size: 32px;
  }

  .extrabold-h1-heading-8 {
    font-size: 32px;
    line-height: 48px;
  }

  .container-3-copy {
    width: 95%;
    flex-direction: column;
  }

  .frame-1085 {
    text-align: center;
  }

  .extrabold-h1-heading-9 {
    margin-left: auto;
    margin-right: auto;
    font-size: 32px;
  }
}

@media screen and (max-width: 479px) {
  .button {
    width: 70%;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .colum {
    justify-content: flex-start;
  }

  .grid {
    width: 88.33%;
  }

  .container {
    text-align: left;
  }

  .container.adaptive {
    width: 320px;
  }

  .row {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav, .boton-generador {
    width: 100%;
  }

  .wrapper-video {
    justify-content: space-between;
  }

  .vectors-wrapper-3 {
    display: none;
  }

  .bold-h3-heading-3 {
    line-height: 24px;
  }

  .div-block-2 {
    width: 90%;
  }

  .bold-h3-heading-4 {
    text-align: left;
    line-height: 28px;
  }

  .logo-header {
    width: 150px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    object-fit: fill;
  }

  .vectors-wrapper-5 {
    object-fit: contain;
  }

  .hero-heading-left {
    background-image: linear-gradient(to bottom, #3B45BF 47%, rgba(114, 124, 245, 0)), url('assets/images/principal-transparente.png'), linear-gradient(113.52deg, #3B45BF, #727cf5);
    background-position: 0 0, 50% 100%, 0 0;
    background-repeat: repeat, no-repeat, repeat;
    background-size: auto, 500px, auto;
  }

  .container-3 {
    width: 95%;
    flex-direction: column;
    padding-top: 20px;
  }

  .vectors-wrapper-6 {
    width: 100px;
    height: auto;
  }

  .wrapper-hero {
    width: auto;
    padding-bottom: 20px;
  }

  .brand {
    width: auto;
  }

  .conteiner-nav {
    width: 95%;
    flex-direction: row;
  }

  .extrabold-h1-heading {
    text-align: center;
  }

  .lead-text---regular-2 {
    line-height: 25px;
  }

  .container-3-item {
    width: 95%;
    flex-direction: column;
  }

  .vector {
    width: 100%;
    height: 44%;
  }

  .frame-10 {
    max-width: none;
  }

  .frame-4 {
    height: auto;
  }

  .button-5.body-strong-2 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .extrabold-h1-heading-5, .extrabold-h1-heading-6, .extrabold-h1-heading-7 {
    font-size: 32px;
  }

  .container-3-copy {
    width: 95%;
    flex-direction: column;
  }

  .paragraph-6, .paragraph-7, .paragraph-8 {
    line-height: 25px;
  }

  .div-block-9 {
    flex-direction: column;
  }

  .wrapper-modal {
    object-fit: fill;
    display: none;
  }

  .frame-1088 {
    width: 95%;
  }

  .frame-1087 {
    width: 100%;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .wrapper-7 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .frame-1091 {
    display: none;
  }
}

#w-node-_0526d6a9-9056-3ff2-69f2-daaf92a4d2e9-75810f05, #w-node-da848658-529b-a848-5936-480578f029bd-75810f05, #w-node-f9f9a6bb-1fa7-52d1-0755-2606480e45fd-75810f05 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}



:root { 
--gray_900_14:#14132a14; 
--indigo_A100:#929efb; 
--red_400:#de5d48; 
--black_900_3f:#0000003f; 
--teal_100:#b3efde; 
--indigo_A200_7f:#727cf57f; 
--teal_300:#5ecb9b; 
--black_900_dd:#000000dd; 
--yellow_800:#e79a24; 
--indigo_A700_01:#1a43f5; 
--red_300_7f:#e8677e7f; 
--deep_orange_900:#ac4a1d; 
--pink_300:#fa5c7c; 
--gray_600:#6c757d; 
--teal_A700:#0acf97; 
--gray_400:#d5c3bb; 
--blue_gray_100:#ced4da; 
--amber_500:#ffc107; 
--blue_gray_300:#9795b4; 
--gray_800:#343a40; 
--lime_100:#f5f2cd; 
--black_900_99:#00000099; 
--indigo_A700:#1a3bff; 
--white_A700:#ffffff; 
--deep_orange_50:#faefea; 
--blue_gray_900_19:#2b2b2b19; 
--blue_gray_50:#e9ecef; 
--blue_A700:#2761ff; 
--red_900:#883617; 
--indigo_A200:#727cf5;  
--red_300:#e8677e; 
--red_400_01:#dc5d3d; 
--gray_50:#f8f9fa; 
--teal_200:#80caca; 
--black_900_1e:#0000001e; 
--blue_gray_200_01:#adb5bd; 
--black_900:#000000; 
--pink_800:#a5243e; 
--black_900_28:#00000028; 
--gray_900_02:#252222; 
--lime_200:#ece5a9; 
--gray_700:#54595e; 
--gray_900_03:#2d201b; 
--blue_gray_200:#abb5be; 
--gray_900_04:#191919; 
--gray_900_05:#06002c; 
--gray_900:#212529; 
--gray_900_01:#422e22; 
--orange_300:#f7c244; 
--gray_300:#ede0d9; 
--gray_300_02:#e0e0e0; 
--gray_300_01:#dee2e6; 
--gray_100:#f1f2fe; 
--indigo_300:#737fed; 
--light_green_50:#f2f0e4; 
}